//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
import teamOptions from "@/mixins/teamOptions";
export default {
  mixins: [teamOptions],
  components: {
    Header: () => import("@/components/Header"),
    BaseView: () => import("@/components/BaseView"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
    TaskDetailRight: () => import("./taskdetail-right"),
  },
  data() {
    return {
      functionName: "任务管理",
      IsRealTimeScreenshot: true,
      activeTime: "",
      ulTimeArr: [],
      expandId: null,
      selMem: [],
      loading: false,
      taskArray: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.initPage();
    if (
      this.$route.params.teamId &&
      this.$route.params.userId &&
      this.$route.params.date
    ) {
      this.teamValue = Number.parseInt(this.$route.params.teamId);
      this.expandId = this.$route.params.date;
      this.selMem = [{ UsId: Number.parseInt(this.$route.params.userId) }];
      this.getTask();
    }
  },
  methods: {
    imgChange,
    handleGetData() {
      if (!this.selMem || this.selMem.length == 0) {
        this.selMem = [this.user];
        this.getTask();
      }
    },
    /**
     * 团队切换
     */
    handleTeamChange(val) {
      const team = this.teamOptions.find((m) => m.Id == val);
      if (team) {
        this.IsRealTimeScreenshot = team.IsRealTimeScreenshot; //是否显示实时截图
      }
      this.selMem = [];
    },
    /**
     * 获取任务
     */
    getTask() {
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      if (!this.selMem.length) {
        this.$message({
          message: "请先选择成员",
          type: "warning",
        });
        return;
      }
      this.$router.replace({
        name: "taskDetails1",
        params: {
          ...this.$route.params,
          teamId: this.teamValue,
          userId: this.selMem[0].UsId,
          date: this.expandId,
        },
      });
      this.loading = true;
      const data = {
        date: this.expandId,
        page: 1,
        pageCount: 100,
        teamId: this.teamValue,
        userId: this.selMem[0].UsId,
      };
      this.$http
        .post("/Task/GetMemberTaskList.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.taskArray = resp.data.Data;
          }
        })
        .then(() => (this.loading = false));
    },
    /**
     * 获取成员
     */
    getSelMember(val) {
      this.selMem = val;
      this.getTask();
      console.log(val);
    },
    // 左侧li点击事件
    liClick(index) {
      this.expandId = index;
      this.getTask();
    },
    // 初始化ul 时间列表
    initPage() {
      this.getCalendar3Y();
      this.expandId = this.ulTimeArr[0].brr[0].time;
    },
    getCalendar3Y(time = new Date()) {
      let nowy = time.getFullYear();
      let nowm = time.getMonth();
      let nowd = time.getDate();

      let y = time.getFullYear();
      let m = time.getMonth(); // 0~11
      let arr = [];
      // n = 123456789控制年数
      for (let n = 0; n < 2; n++) {
        for (let i = m + 1; i > 0; i--) {
          let item = {
            title: y + "年" + (i <= 9 ? "0" : "") + i + "月",
            brr: new Array(),
          };
          for (
            let j = 0, days = this.getMonthDays(y, i - 1).length;
            j < days;
            j++
          ) {
            if (nowy === y && nowm + 1 === i && nowd <= j) {
              //    这节不要 比如今天是2019年11月10号，那这一个月的11~30不展示
            } else {
              item.brr.push({
                time:
                  y +
                  "-" +
                  (i <= 9 ? "0" : "") +
                  i +
                  "-" +
                  (j < 9 ? "0" : "") +
                  (j + 1),
              });
            }
          }
          item.brr = item.brr.reverse();
          arr.push(item);
        }
        y--;
        m = 11;
      }
      this.ulTimeArr = arr;
      // console.log(arr)
    },
    // 判断平年闰年 [四年一闰，百年不闰，四百年再闰]
    isLeapYear(y) {
      return y % 400 === 0 || (y % 100 !== 0 && y % 4 === 0);
    },
    // 获得每月各自的总天数组成的数组，特殊 month [0~11]
    getMonthDays(y, m) {
      let arr = [31, null, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      let count = arr[m] || (this.isLeapYear(y) ? 29 : 28);
      return Array.from(new Array(count), (item, value) => value + 1);
    },
  },
};
